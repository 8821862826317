var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm._m(0);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticClass: "big"
  }, [_c("div", {
    staticClass: "inner"
  }, [_c("img", {
    attrs: {
      src: require("../../assets/notFound.png")
    }
  })])])]);
}];
render._withStripped = true;
export { render, staticRenderFns };